<template>
  <div>
    <Icon tooltip="Novas funcionalidades" placement="bottom">
      <Gift class="gift" v-b-toggle.sidebar-features />
    </Icon>
    <Home />
  </div>
</template>

<script>

export default {
  components: {
    Icon: () => import('@/components/General/Icon'),
    Home: () => import('@/modules/featureRequests/views/Home'),
    Gift: () => import('@/assets/icons/gift.svg'),
  }
}
</script>
<style lang="scss" scoped>
.gift {
  width: 24px;
  height: 24px;
  fill: #7b89bc;
}
</style>